@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-image: url("../../public/images/bg.webp"), url("../../public/images/texture.png");
  background-position: center center, 0% 0%;
  background-repeat: no-repeat, repeat;
  background-size: 1440px 1053px, 60.90909242630005px 60.90909242630005px;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  background-attachment: scroll;
}

div {
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  body {
    background-image: url("../../public/images/mobile-bg.webp"), url("../../public/images/texture.png");
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 0;
}
