.container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  color: white;
  height: 100%;
  width: 600px;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  color: white;
  height: 100%;
  justify-content: center;
}
.emoji {
  font-size: 100px;
}
.offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.offer_label {
  font-size: 48px;
  background-clip: text;
  background: linear-gradient(91deg, #63A9F7 0%, #9362FF 100%);
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.offer_desc {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  position: sticky;
  bottom: 16px;
}
.discount {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.discount_label {
  font-size: 20px;
  color: #4FFF8D;
  font-weight: 700;
}
.discount_desc {
  font-size: 16px;
  font-weight: 400;
  color: white;
  opacity: 0.6;
  text-decoration: line-through;
  font-style: normal;
}
.pay_desc {
  font-size: 16px;
  font-weight: 400;
  color: white;
  opacity: 0.6;
  font-style: normal;
  text-align: center;
}
.button {
  height: 48px;
  width: 100%;
  font-size: 16px;
}
.countdown {
  display: flex;
  gap: 12px;
}
.countdown__items {
  display: flex;
  gap: 4px;
}
.countdown__item {
  display: flex;
  width: 28px;
  height: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--Color-Stroke, rgba(255, 255, 255, 0.16));
  background: var(--Color-Secondary-bg, rgba(21, 21, 21, 0.4));
  font-size: 20px;
}
.countdown__block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.countdown__block_label {
  opacity: 0.7;
  font-size: 14px;
}
.countdown__divider {
  font-size: 32px;
  font-weight: 700;
  opacity: 0.6;
}

.closeButton {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 16px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(21, 21, 21, 0.40);
  cursor: pointer;
}

.closeButton:hover {
  background: rgba(21, 21, 21, 1);
}

@media screen and (max-height: 700px) {
  .footer {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
}
