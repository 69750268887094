.container {
  display: flex;
  flex-direction: column;
  width: 600px;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  height: 100%;
  gap: 40px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }

  .content {
    gap: 24px;
  }
}
