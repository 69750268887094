.button {
  width: 100%;
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 100px;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  border: none;
  cursor: pointer;
  color: #151515;
  position: relative;
}

.button:after {
  border-radius: 100px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(21, 21, 21, 0.12);
  transition: opacity 300ms ease-in;
}

.button:hover:after {
  opacity: 1;
}

.button:disabled {
  cursor: not-allowed;
  color: rgba(21, 21, 21, 0.6);
}
