.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  color: #ffffff;
  padding: 24px;
}

.container h1 {
  font-size: 32px;
  margin: 0;
}

.container h2 {
  font-size: 24px;
  margin: 0;
}

.list a {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 100px;
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  font-family: Outfit;
  cursor: pointer;
  background-color: #ffffff;
  text-decoration: none;
  color: #000000;
}

.container button:hover {
  opacity: 0.7;
}

.container ol {
  padding-left: 16px;
}

.wrapper {
  max-width: 400px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 32px;
}

a.downloadButton {
  background: linear-gradient(91deg, #63A9F7 0%, #9362FF 100%);
  color: #ffffff;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.support {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.support a {
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0;
  }

  .container h1 {
    font-size: 28px;
  }

  .container h2 {
    font-size: 20px;
  }
}
