.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.copyright {
  color: #FFFFFF;
}

.social {
  display: flex;
  gap: 24px
}

@media screen and (max-width: 767px) {
  .footer {
    display: none;
  }
}
