.wrapper {
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 16px;
	width: 100%;
	cursor: pointer;
	justify-content: space-between;
  }
  .toggleSwitch {
	position: relative;
	display: inline-block;
	width: 52px;
	height: 32px;
  }
  .toggleSwitch input[type="checkbox"] {
	display: none;
  }
  .toggleSwitch input[type="checkbox"]:checked + .switch::before {
	transform: translateX(20px);
  }
  .toggleSwitch input[type="checkbox"]:checked + .switch {
	background: linear-gradient(97deg, #F3F800 0%, #F90 100%);
  }
  .toggleSwitch .switch {
	position: absolute;
	cursor: pointer;
	background-color: #2B2D31;
	border-radius: 32px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 0.2s ease;
  }
  .toggleSwitch .switch::before {
	position: absolute;
	content: "";
	left: 2px;
	top: 2px;
	width: 28px;
	height: 28px;
	background-color: #ffffff;
	border-radius: 50%;
	transition: transform 0.3s ease;
  }
  