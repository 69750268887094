a {
  color: #9362FF;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 8px;
  animation: fade-in .4s ease;
}

.title h3 {
  font-family: Outfit;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.title p {
  opacity: 0.7;
  margin: 0;
  line-height: normal;
}

.answers {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  animation: fade-in .4s ease;
}

.answer label {
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(21, 21, 21, 0.40);
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  transition: background-color 200ms ease-out, border-color 200ms ease-out;
  animation: fade-in .4s ease-out;
}

.answer input {
  display: none;
}

.answer input:checked + label {
  border-color: #9362FF;
  background-color: rgba(147, 98, 255, 0.08);
}

.inputLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.image {
  max-width: 100%;
  height: 100%;
  border-radius: 16px;
  animation: fade-in .4s ease-out;
  max-height: calc(100dvh - 250px);
}

@media screen and (max-width: 767px) {
  .title h3 {
    font-size: 24px;
  }

  .image {
    max-width: 100%;
    width: auto;
    height: calc(100dvh - 250px);
    object-fit: cover;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.emailInput {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(21, 21, 21, 0.40);
  height: 48px;
  padding: 0 16px;
  padding-left: 50px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  position: inherit;
  width: 100%;
  outline: none;
  box-sizing: border-box;
}

.emailInputError {
  border-color: #FB5D67;
}

.emailInput::-webkit-input-placeholder,
.emailInput::placeholder  {
  color: white;
}

.emailinputIcon {
  position: absolute;
  top: 0;
  left: 16px;
  width: 24px;
  height: 24px;
  background-image: url(../../../../assets/email-icon.svg);
  z-index: 1;
  top: 12px;
}

.emailInputContainer {
  position: relative;
}

.privacy {
  color: rgba(255, 255, 255, 0.70);
  font-size: 16px;
}

.inputError {
  color: #FB5D67;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
