.wrapper {
  display: flex;
  gap: 8px;
  color: #FFFFFF;
  font-size: 20px;
  align-items: center;
}

.loader {
  animation: rotate 8s infinite;
  height: 20px;
  width: 20px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 7px;
  width: 7px;
}
.loader:before {
  animation: ball1 2s infinite;
  background-color: #9362FF;
  box-shadow: 12px 0 0 #9362FF;
  margin-bottom: 4px;
}
.loader:after {
  animation: ball2 2s infinite;
  background-color: #9362FF;
  box-shadow: 12px 0 0 #9362FF;
}

@keyframes rotate {
  0% { transform: rotate(0deg) scale(0.8) }
  25% { transform: rotate(180deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(0.8) }
  75% { transform: rotate(540deg) scale(0.8) }
  100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes ball1 {
  0% {
    box-shadow: 12px 0 0 #9362FF;
  }
  50% {
    box-shadow: 0 0 0 #9362FF;
    margin-bottom: 0;
    transform: translate(6.5px, 6.5px);
  }
  100% {
    box-shadow: 12px 0 0 #9362FF;
    margin-bottom: 4px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 12px 0 0 #9362FF;
  }
  50% {
    box-shadow: 0 0 0 #9362FF;
    margin-top: -7px;
    transform: translate(6.5px, 6.5px);
  }
  100% {
    box-shadow: 12px 0 0 #9362FF;
    margin-top: 0;
  }
}

