.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  padding: 40px 24px;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 16px;
    padding-bottom: 0;
  }
}
