.steps {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.step {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.16);
  flex-shrink: 0;
  transition: background 400ms ease-in, border-color 500ms ease-in;
}

.line {
  height: 2px;
  flex: 1 0 0;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.16);
  width: 100%;
  transition: background 300ms ease-in;
}

.stepLast {
  display: flex;
  align-items: center;
  gap: 4px;
  width: calc(100% - 145px);
}

.active .line {
  background: #9362FF;
}

.active .circle {
  border-color: #9362FF;
  background-color: #9362FF;
}


