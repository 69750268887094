.wrapper {
  width: 100%;
  position: sticky;
  padding-top: 8px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
}

.button {
  width: 100%;
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 100px;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  border: none;
  cursor: pointer;
  color: #151515;
  position: relative;
}

.button:after {
  border-radius: 100px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(21, 21, 21, 0.12);
  transition: opacity 300ms ease-in;
}

.button:hover:after {
  opacity: 1;
}

.button:disabled {
  cursor: not-allowed;
  color: rgba(21, 21, 21, 0.6);
}

.auth_buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    display: flex;
    height: 48px;
    font-size: 16px;
    align-items: center;
    gap: 8px;
  }
}

.divider {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  font-size: 12px;
  position: relative;
  align-items: center;
  gap: 16px;
  color: white;

  &:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.16);
    width: 100%;
    height: 1px;
  }

  &:after {
    content: '';
    background-color: rgba(255, 255, 255, 0.16);
    width: 100%;
    height: 1px;
  }
}