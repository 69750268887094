.videoWrapper {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.videoBg {
  background: #151515;
  z-index: -1;
}

.videoBg video {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 470px);
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  background: linear-gradient(270deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
}

.videoBgEffect {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 470px);
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  background: linear-gradient(270deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
}

.container {
  padding: 32px;
  height: 100svh;
  display: flex;
  align-items: center;
}

.content {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #ffffff;
}

.content h3 {
  font-size: 20px;
  margin: 0;
}

.content ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  padding-left: 0;
  font-weight: 500;
}

.content ul li {
  display: block;
  background: url("../../assets/marker.svg") 0 -2px no-repeat;
  padding-left: 32px;
}

.logo {
  display: flex;
  gap: 8px;
}

.logoLabel {
  background-color: #ffec45;
  color: #151515;
  border-radius: 100px;
  height: 32px;
  font-weight: 900;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.plans {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.plan {
  position: relative;
}

.plan label {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  cursor: pointer;
}

.plan input:checked + label {
  border-color: #ffec45;
}

.costPeriod {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
}

.costDay {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.discount {
  display: none;
  height: 24px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  top: -12px;
  border-radius: 8px;
  background-color: #ffec45;
  color: #151515;
  font-size: 16px;
  font-weight: 700;
}

.plan input:checked ~ .discount {
  display: flex;
}

.button {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 100px;
  border: none;
  background-color: #ffec45;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #151515;
  font-family: Outfit;
  cursor: pointer;
  position: relative;
}

.button:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(21, 21, 21, 0.12);
  transition: opacity 300ms ease-in;
}

.button:hover:after {
  opacity: 1;
}

.button:disabled {
  cursor: not-allowed;
  color: rgba(21, 21, 21, 0.6);
}

.closeButton {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 16px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(21, 21, 21, 0.4);
  cursor: pointer;
}

.closeButton:hover {
  background: rgba(21, 21, 21, 1);
}

@media screen and (max-width: 767px) {
  .container {
    justify-content: center;
    width: 100%;
    padding: 16px;
    align-items: end;
  }

  .content {
    width: 100%;
  }

  .plan label {
    padding: 14px 24px;
  }

  .costPeriod {
    font-size: 16px;
  }

  .costDay {
    font-size: 20px;
  }

  .videoBg video {
    height: calc(100% - 320px);
    left: 0;
    width: 100%;
  }

  .videoBgEffect {
    height: calc(100% - 320px);
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
  }
}
