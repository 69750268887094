.slider {
  position: relative;
  overflow: hidden;
  border-radius: 18px;
  max-height: calc(100dvh - 250px);
}
.slider__images_container {
  position: relative;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider__before_img {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider__line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.25rem;
  background-color: #ffffff;
  cursor: ew-resize;
}
.slider__line .circle {
  position: absolute;
  left: -14px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  bottom: calc(50% - 16px);
  background-image: url('../../../../assets/left_right_arrows.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.slider .top_label {
  position: absolute;
  padding: 4px 8px;
  background: rgba(21, 21, 21, 0.4);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #FFFFFF;
}
.slider .top_label_left {
  top: 8px;
  left: 8px;
}
.slider .top_label_right {
  top: 8px;
  right: 8px;
}
.slider img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}
